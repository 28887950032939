<template>
    <section class="section home">
        <div class="home-header">
            <div class="home-header-col">
                <!-- <h1 class="home__title" v-if="first_name !== ''">Добрый день, {{ first_name }}!</h1>
				<h1 class="home__title" v-else="first_name !== ''">Добрый день, {{ username }}!</h1> -->
                <p class="home__subtitle">Сегодня {{ current_day }}, {{ current_date }}</p>
            </div>
        </div>
        <div class="home-body">
            <div class="col-2">
                <div class="window window-goals window_full">
                    <div class="window-header">
                        <img src="@/assets/img/icons/icon-goals.svg" alt="Иконка" class="window-header__icon" />
                        <div class="window-header__title">
                            Главные цели на месяц <span>( {{ goalsCount.completed }} / {{ goalsCount.total }} )</span>
                        </div>
                    </div>
                    <div v-if="goalsList.length === 0" class="window-item window-item_empty">
                        <p>Нет целей</p>
                    </div>
                    <div class="window-item" v-for="goal in goalsList" :key="goal.id">
                        <p :class="goal.is_completed ? 'complete' : ''">{{ goal.name }}</p>
                        <div class="window-item-right">
                            <span v-if="goal.is_completed" class="window-item__status window-item__status_complete">
                                <div class="circle"></div>
                                сделано
                            </span>
                            <span> {{ goal.current_value }} / {{ goal.planned_value }} </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="window window-metriks window-metriks_main window_full">
                    <div class="window-header">
                        <img src="@/assets/img/icons/icon-metriks.svg" alt="Иконка" class="window-header__icon" />
                        <div class="window-header__title">Мои главные метрики</div>
                    </div>
                    <div class="window-body">
                        <div class="window-item_metriks" v-for="item in metriks" :key="item.id">
                            <div v-if="item.name === 'Выручка по сети Тюбетей'" class="window-item-icon">
                                <img src="@/assets/img/icons/metriks/icon-metrik-1.svg" alt="Иконка" />
                            </div>
                            <div v-if="item.name === 'Трафик по сети Тюбетей'" class="window-item-icon">
                                <img src="@/assets/img/icons/metriks/icon-metrik-2.svg" alt="Иконка" />
                            </div>
                            <div v-if="item.name === 'Средний чек по сети Тюбетей'" class="window-item-icon">
                                <img src="@/assets/img/icons/metriks/icon-metrik-3.svg" alt="Иконка" />
                            </div>
                            <div class="window-item-data">
                                <div class="window-item-data__title">{{ item.value }}</div>
                                <div class="window-item-data__subtitle">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2" v-if="getDashboardTasks">
                <div class="window window-tasks window_full">
                    <div class="window-header">
                        <img src="@/assets/img/icons/icon-tasks.svg" alt="Иконка" class="window-header__icon" />
                        <router-link to="/tasks" class="window-header__title"
                            >Ближайшие задачи <span>( {{ getDashboardTasks.length }} )</span></router-link
                        >
                    </div>

                    <div
                        class="window-item window-item_tasks"
                        v-for="task in getDashboardTasks"
                        :key="task.id"
                        :class="
                            (task.description ? 'window-item_tasks_with-description' : '',
                            getDashboardTasks.length === 1 ? 'only_one' : '')
                        "
                    >
                        <div class="window-item-header">
                            <input
                                v-model="task.is_completed"
                                :id="'task_' + task.id"
                                type="checkbox"
                                class="checkbox checkbox_complete"
                            />
                            <label :for="'task_' + task.id" @click="changeTaskStatus(task.id, task.is_completed)">{{
                                task.name
                            }}</label>
                            <div class="window-item-right">
                                <span v-if="task.is_priority" class="window-item__status window-item__status_priority">
                                    <div class="circle"></div>
                                    важно
                                </span>
                                <span
                                    class="window-item__status"
                                    :class="task.is_expired ? ' window-item__status_expired' : ''"
                                >
                                    {{ new Date(task.date_to).toLocaleDateString() }}
                                </span>
                            </div>
                        </div>
                        <div class="window-item-body" v-if="task.description">
                            {{ task.description }}
                        </div>
                    </div>
                    <div v-if="getDashboardTasks.length === 0" class="window-item window-item_empty">
                        <p>У вас пока нет задач</p>
                    </div>
                </div>
            </div>
            <div class="col-4" v-if="users">
                <div class="window window-users window_full">
                    <div class="window-header">
                        <img src="@/assets/img/icons/icon-tasks.svg" alt="Иконка" class="window-header__icon" />
                        <p class="window-header__title">
                            Адресная книга
                        </p>

                        <input type="text" v-model="user_search" class="input window-users__search" placeholder="Поиск">
                        <span class="window-users__search_icon">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z" stroke="#7992F9" stroke-width="1.1"/>
                                <path d="M16 16L13 13" stroke="#7992F9" stroke-width="1.1" stroke-linecap="round"/>
                            </svg>
                        </span>
                    </div>

                    <div class="window-users-table">
                        <div class="window-users-table-item window-users-table-item_header">
                            <div class="col">Сотрудник</div>
                            <div class="col">Должность</div>
                            <div class="col">Номера телефона</div>
                        </div>
                        <div
                            class="window-users-table-item"
                            v-for="user in users.filter(user => user.first_name.toLowerCase().includes(this.user_search.toLowerCase()))"
                            :key="user.id"
                        >
                            <router-link :to="`/users/${user.id}`" class="col link">{{ user.first_name }} {{ user.last_name }}</router-link>
                            <div class="col roles">
                                <p v-for="role in user.roles" :key="role.id">{{ role.name }}<span>,&nbsp;</span></p>
                            </div>
                            <div class="col"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
    name: "HomeView",

    data() {
        return {
            task_select: "неделя",
            current_date: "",
            current_day: "",
            current_time: "",
            user_search: ''
        };
    },

    computed: {
        ...mapState({
            tcoins: (state) => state.tcoins,
            goalsList: (state) => state.home.goalsList,
            goalsCount: (state) => state.home.goalsCount,
            metriks: (state) => state.home.metriks,
            notifications: (state) => state.home.notifications,
            users: (state) => state.home.users
        }),

        ...mapGetters({
            getDashboardTasks: 'tasks/getDashboardTasks'
        })
    },

    methods: {
        ...mapActions({
            getGoalsList: "home/getGoalsList",
            getTasksList: "tasks/getTasksList",
            completeTask: "tasks/completeTask",
            getMetriks: "home/getMetriks",
            getShop: "home/getShop",
            getNotifications: "home/getNotifications",
            getUsers: "home/getUsers",
        }),

        changeTaskStatus(id, status) {
            let data = {
                status: status,
                id: id,
            };

            this.completeTask(data);
        },

        getCurrentDate() {
            let date = new Date();
            let monthNames = [
                "января",
                "февраля",
                "марта",
                "апреля",
                "мая",
                "июня",
                "июля",
                "августа",
                "сентября",
                "октября",
                "ноября",
                "декабря",
            ];
            let dayNames = ["воскресенье", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"];
            let day = date.getUTCDate();
            let month = monthNames[date.getUTCMonth()];
            let year = date.getUTCFullYear();

            this.current_date = day + " " + month + " " + year;
            this.current_day = dayNames[new Date().getDay()];
        },
    },

    mounted() {
        this.getMetriks();
        this.getGoalsList();
        this.getTasksList();
        this.getCurrentDate();
        this.getNotifications();
        this.getUsers()
    },
};
</script>
<style scoped lang="sass">
.col-4
    grid-column: 1 / -1

.window-users
    & .window-header
        position: relative
    &__search
        margin-left: auto
        width: 200px
        height: 28px
        border: none
        &_icon
            position: absolute
            top: 50%
            right: 26px
            transform: translateY(-40%)
    &-table
        display: flex
        flex-direction: column
        align-items: flex-start
        &-item
            display: grid
            grid-template-columns: 50% 25% 25%
            align-items: center
            width: 100%
            &_header
                color: #808F9F
            &:nth-child(even)
                background: #FAFAFA
            & .col
                padding: 0 18px
                display: flex
                align-items: center
                height: 50px
                overflow-x: hidden 
                &:not(:last-child)
                    border-right: 1px dotted #D8D8D8
                &.link
                    font-weight: 600
                    color: var(--color-blue)
                &.roles
                    & p
                        white-space: nowrap
                        &:last-child span
                            display: none
</style>
