import axios from 'axios'
import api from '@/api'

export const learningModule = {
    namespaced: true,

    state:() => ({
        loading: true,
        chapters: null,
        polls: null,
        test: null,
        poll_progress_id: null,
        points: null
    }),

    mutations: {
        setChapters(state, data) {
            state.chapters = data
        },

        setPolls(state, data) {
            state.polls = data
        },

        setTest(state, data) {
            state.test = data
        },

        setPollProgressId(state, data) {
            state.poll_progress_id = data
        }
    },

    actions: {
        getChapters({ commit }) {
            axios
            .get(api + "/chapters/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setChapters', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        getPolls({ commit }) {
            axios
            .get(api + "/polls/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                console.log(response.data);
                commit('setPolls', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        getTest({ state, commit }, id) {
            state.points = null

            axios
            .get(api + "/polls/" + id, {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setTest', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        startTest({ commit }, id) {
            axios
            .post(api + "/poll-progress/", {
                poll_id: id
            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setPollProgressId', response.data.id)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        selectAnswer({}, data) {
            axios
            .post(api + "/answers/",
            data,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        endTest({ state }) {
            axios
            .put(api + "/poll-progress/", {
                poll_progress_id: state.poll_progress_id,
                is_completed: true

            },
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                if(response.data.is_completed) {
                    state.points = response.data.correct_answers_count
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        }
    }
}