import axios from 'axios'
import api from '@/api'
import moment from 'moment'

export const projectsModule = {
    namespaced: true,

    state: () => ({
        points_of_sale: null,
        loading: false,
        shop: null,
        projects: {},
        departments: [],
    }),

    mutations: {
        setProjects(state, data) {
            console.log(data);
            state.projects = {}
            state.departments = []

            state.departments.push({
                value: 'all',
                label: 'Все'
            })

            if (data.length !== 0) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].date_from || data[i].date_to) {
                        let date_to = moment(data[i].date_to, 'DD-MM-YYYY')
                        let current_date = moment(new Date().toLocaleDateString(), 'DD-MM-YYYY')
                        var days = date_to.diff(current_date, 'days');

                        data[i].deadline = days
                    }

                    for (let e = 0; e < data[i].departments.length; e++) {
                        data[i].departments[e].value = data[i].departments[e].name
                        data[i].departments[e].label = data[i].departments[e].name

                        const elem = state.departments.find(item => item.name == data[i].departments[e].name)
                        if(!elem) {
                            state.departments.push(data[i].departments[e])
                        }
 
                        if (!state.projects[data[i].departments[e].name]) {
                            state.projects[data[i].departments[e].name] = []
                        }

                        state.projects[data[i].departments[e].name].push(data[i])
                    }
                }
            }
            // if(data.length !== 0) {
            //     state.projects.push({
            //         id: 0,
            //         name: 'Другие',
            //         color: '#4E4E4E',
            //         projects: []
            //     })

            //     for(let i = 0; i < data.length; i++) {
            //         if(data[i].date_from || data[i].date_to) {
            //             data[i].date_from = new Date(data[i].date_from).toLocaleDateString()
            //             data[i].date_to = new Date(data[i].date_to).toLocaleDateString()

            //             let date_to = moment(data[i].date_to, 'DD-MM-YYYY')
            //             let current_date = moment(new Date().toLocaleDateString(), 'DD-MM-YYYY')
            //             var days = date_to.diff(current_date, 'days');

            //             data[i].deadline = days
            //         }
            //     }

            //     for(let i = 0; i < data.length; i++) {
            //         if(data[i].stage && !state.projects.find(item => item.id === data[i].stage.id)) {
            //             data[i].stage['projects'] = []
            //             state.projects.push(data[i].stage)
            //         }
            //     }

            //     for(let i = 0; i < data.length; i++) {
            //         for(let e = 0; e < state.projects.length; e++) {
            //             if(data[i].stage) {
            //                 if(data[i].stage.id === state.projects[e].id) {
            //                     state.projects[e].projects.push(data[i])
            //                 }
            //             } else if (state.projects[e].id === 0) {
            //                 state.projects[e].projects.push(data[i])
            //             }
            //         }
            //     }

            //     for(let i = 0; i < data.length; i++) {
            //         data[i].department.value = data[i].department.name
            //         data[i].department.label = data[i].department.name

            //         if(!state.departments.find(item => item.id === data[i].department.id)) {
            //             state.departments.push(data[i].department)
            //         }
            //     }
            // }
            state.loading = false
        },

        setPointsOfSale(state, data) {
            if (data.length > 0) {
                state.points_of_sale = data
                for (let i = 0; i < data.length; i++) {
                    state.points_of_sale[i].label = data[i].name
                    state.points_of_sale[i].value = String(data[i].code)
                }
            }
        },
    },

    actions: {
        getProjects({ commit, state }) {
            state.loading = true
            state.departments = []
            axios
                .get(api + "/" + state.shop + "/project-tasks", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    commit('setProjects', response.data)
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },

        async getPointOfSale({ commit }) {
            try {
                const response = await axios.get(api + "/shops?report=projects", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setPointsOfSale', response.data)
            } catch (err) {
                alert(err)
            }
        },

        async changeComment({ state, dispatch }, data) {
            state.loading = true
            axios
                .patch(api + "/" + state.shop + "/project-tasks/" + data.id + '/', {
                    comment: data.comment,
                },
                    {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                .then((response) => {
                    setTimeout(() => dispatch('getProjects', state.shop), 2500);
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },

        async changeProjectStatus({ state, dispatch }, data) {
            state.loading = true
            axios
                .patch(api + "/" + state.shop + "/project-tasks/" + data.id + '/', {
                    is_completed: !data.status,
                },
                    {
                        headers: {
                            Authorization: 'Token ' + localStorage.getItem('token')
                        }
                    })
                .then((response) => {
                    dispatch('getProjects')
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },

        async changeDateFrom({ state, commit, dispatch }, data) {
            state.loading = true
            let date_from = null

            if(typeof data.value !== 'string') {
                date_from = data.value.toLocaleDateString().split('.').reverse().join('-')
            }
            date_from = date_from + 'T' + '00:00' + ':00+03:00'
                        
            axios
            .patch(api + "/" + state.shop + "/project-tasks/" + data.id + '/', {
                    date_from: date_from,
                },
                {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                setTimeout(() => dispatch('getProjects'), 2500);
            })
            .catch((error) => {
                console.log(error.response);
            });
            try {
                const response = await axios.get(api + "/shops?report=projects", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setPointsOfSale', response.data)
            } catch (err) {
                alert(err)
            }
        },


        setShop({ state, dispatch }, shop) {
            state.shop = shop
            dispatch('getProjects')
        },
    }
}