import router from '@/router';
import axios from 'axios'
import api from '@/api'

export const dayModule = {
    namespaced: true,

    state:() => ({
        shift_id: null,
        schedule: null,
        roles: [],
        sections: [],
        selectedSection: null,
        employees: [],
        shifts: [],
        table: null,
        bindingShiftData: null,
        times: [
           { label: '07:00', value: '07:00' },
           { label: '07:30', value: '07:30' },
           { label: '08:00', value: '08:00' },
           { label: '08:30', value: '08:30' },
           { label: '09:00', value: '09:00' },
           { label: '09:30', value: '09:30' },
           { label: '10:00', value: '10:00' },
           { label: '10:30', value: '10:30' },
           { label: '11:00', value: '11:00' },
           { label: '11:30', value: '11:30' },
           { label: '12:00', value: '12:00' },
           { label: '12:30', value: '12:30' },
           { label: '13:00', value: '13:00' },
           { label: '13:30', value: '13:30' },
           { label: '14:00', value: '14:00' },
           { label: '14:30', value: '14:30' },
           { label: '15:00', value: '15:00' },
           { label: '15:30', value: '15:30' },
           { label: '16:00', value: '16:00' },
           { label: '16:30', value: '16:30' },
           { label: '17:00', value: '17:00' },
           { label: '17:30', value: '17:30' },
           { label: '18:00', value: '18:00' },
           { label: '18:30', value: '18:30' },
           { label: '19:00', value: '19:00' },
           { label: '19:30', value: '19:30' },
           { label: '20:00', value: '20:00' },
           { label: '20:30', value: '20:30' },
           { label: '21:00', value: '21:00' },
           { label: '21:30', value: '21:30' },
           { label: '22:00', value: '22:00' },
           { label: '22:30', value: '22:30' }
        ],
        loading_autofill: false
    }),

    getters: {
        getDate() {
            let date = new Date(router.currentRoute.value.query.date)
            let format_date = date.getDate() + ' ' + date.toLocaleString('default', { month: 'long' })
            let weeks = [
                'вс',
                'пн',
                'вт',
                'ср',
                'чт',
                'пт',
                'сб',
            ]
            
            return format_date + ', ' + weeks[date.getDay()]
        },

        getRolesEmployees(state) {
            let data = {}

            for(let i = 0; i < state.employees.length; i++) {
                if(state.employees[i].roles) {
                    for(let r = 0; r < state.employees[i].roles.length; r++) {
                        if(state.employees[i].cell === null) {
                            if(data[state.employees[i].roles[r].name]) {
                                data[state.employees[i].roles[r].name].push(state.employees[i])
                            } else {
                                data[state.employees[i].roles[r].name] = [state.employees[i]]
                            }
                        }
                    }
                }
            }
            return data
        },
    },

    mutations: {
        setSections(state, data) {
            state.sections = data
            state.selectedSection = data[0].name
        },

        setTable(state, data) {
            state.table = data
        },

        setRows(state, data) {
            state.rows = data
        },

        setShiftId(state, id) {
            state.shift_id = id
        },

        setRoles(state, data) {
            state.roles = data
        },

        setEmployees(state, data) {
            state.employees = data
        },

        setShifts(state, data) {
            state.shifts = data
        },

        setBindingShift(state, data) {
            state.bindingShiftData = data
        },

        setBindingEmployee(state, data) {
            state.bindingEmployeeData = data
        }
    },

    actions: {
        async fetchSections({ commit, rootState }) {
            rootState.isLoading = true
            let shop = router.currentRoute.value.query.code
            try {
                const response = await axios.get(api + "/" + shop + "/sections/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setSections', response.data)
            } catch(err) { // Если есть ошибка
                console.log('Ошибка при получении секций!');
                return
            }
        },

        tableGenerate({ state, commit }, count) {
            let rows = {}
            for(let i = 0; i < count; i++) {
                rows['row_'+i] = {
                    empty: true,
                    cells: []
                }
                rows['row_'+i].cells.push({
                    cell: 0,
                    row: i,
                    time_from: state.times[0].value
                })

                for(let e = 1; e < state.times.length; e++) {
                    rows['row_'+i].cells.push({
                        cell: e,
                        row: i,
                        time_from: state.times[e].value
                    })
                }
            }
            commit('setTable', rows)
        },

        async fetchEmployees({ commit, dispatch }) {
            let shop = router.currentRoute.value.query.code
            let data = null
            try {
                const response = await axios.get(api + "/" + shop + "/shift-employees/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                data = response.data

                for(let i = 0; i < data.length; i++) {
                    data[i]['color'] = null
                    data[i]['cell'] = null
                    data[i]['row'] = null
                    data[i]['label'] = data[i].name
                    data[i]['value'] = data[i].id
                    for(let e = 0; e < data[i].roles.length; e++) {
                        data[i].roles[e].label = data[i].roles[e].name
                        data[i].roles[e].value = data[i].roles[e].id
                    }
                }
                commit('setEmployees', data)
                dispatch('fetchShifts')
            } catch(err) { // Если есть ошибка
                console.log('Ошибка при получении данных сотрудников!');
                return
            }
        },

        async fetchRoles({ commit }) {
            let shop = router.currentRoute.value.query.code
            try {
                const response = await axios.get(api + "/" + shop + "/shift-roles/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                commit('setRoles', response.data)
            } catch(err) { // Если есть ошибка
                console.log('Ошибка при получении ролей сотрудников!');
                return
            }
        },

        fetchShifts({ commit, state, dispatch, rootState }) {
            let date = router.currentRoute.value.query.date
            let shop = router.currentRoute.value.query.code

            axios
            .get(api + "/"+ shop +"/shifts/" + date,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {    
                state.schedule = response.data.schedule_day

                let data = {}
                if(state.sections.length > 1) {
                    let arr = []
                    for(let i = 0; i < response.data.results.length; i++) {
                        if(response.data.results[i].section) {
                            if(state.selectedSection === response.data.results[i].section.name) {
                                arr.push(response.data.results[i])
                            }
                        }
                    }
                    
                    data = arr
                } else {
                    data = response.data.results

                }

                if(data.length !== 0) {
                    dispatch('tableGenerate', data.length)
                } else {
                    dispatch('tableGenerate', 15)
                }
                
                for(let i = 0; i < data.length; i++) {
                    if(data[i].employee !== null) {
                        for(let e = 0;  e < state.employees.length; e++) {
                            if(data[i].employee.id === state.employees[e].id) {
                                state.employees[e].shift_id = data[i].id
                                state.employees[e].time_from = data[i].time_from.substring(0, 5)
                                state.employees[e].time_to = data[i].time_to.substring(0, 5)
                                state.employees[e].colspan = state.times.findIndex(el => el.value === state.employees[e].time_to) - state.times.findIndex(el => el.value === state.employees[e].time_from) + 1
                                state.employees[e].row = i
                                state.employees[e].section = data[i].section
                                state.employees[e].description = data[i].description

                                for(let t = 0; t < state.times.length; t++) {
                                    if(data[i].time_from.substring(0, 5) === state.times[t].value) {
                                        state.employees[e].cell = t
                                    }
                                }
                            }
                        }
                    } else {
                        let empty_employee = {
                            id: Date.now()+Math.floor(Math.random()*10000),
                            name: null,
                            roles: null,
                            shift_id: data[i].id,
                            time_from: data[i].time_from.substring(0, 5),
                            time_to: data[i].time_to.substring(0, 5),
                            colspan: state.times.findIndex(el => el.value === data[i].time_to.substring(0, 5),) - state.times.findIndex(el => el.value === data[i].time_from.substring(0, 5),) + 1,
                            row: i,
                            section: data[i].section,
                            description: data[i].description,
                        }

                        for(let t = 0; t < state.times.length; t++) {
                            if(data[i].time_from.substring(0, 5) === state.times[t].value) {
                                empty_employee.cell = t
                            }
                        }

                        state.employees.push(empty_employee)
                    }
                }
                commit('setShifts', data)
                dispatch('updateTable')
                rootState.isLoading = false
            })
            .catch((error) => {
                console.log('Ошибка при получении данных по сменам!' + error);
                return
            });
        },

        changeSection({ state, dispatch }, data) {
            state.selectedSection = data

            dispatch('dataGeneration')
        },

        updateTable({ state }) {
            let data = state.table
            let employees = state.employees

            for(let row in data) {
                for(let i = 0; i < data[row].cells.length; i++) {
                    for(let e = 0; e < employees.length; e++) {
                        if(employees[e].cell !== null) {
                            if(data[row].cells[i].row === employees[e].row) {
                                state.table[row].empty = false
                                if(employees[e].cell === data[row].cells[i].cell) {
                                    state.table[row].cells[i].colspan = employees[e].colspan
                                }
                            }
                        }
                    }
                }
            }
        },

        async addShift({ state, dispatch }, data) {
            let date = router.currentRoute.value.query.date
            let shop = router.currentRoute.value.query.code
            let section = null

            if(state.sections.length > 1) {
                section = state.sections.filter(x => x.name === state.selectedSection).map(x => { return x.id; }).toString()
            }

            for(let i = 0; i < state.times.length; i++) {
                if(data.time_from.substring(0, 2) === state.times[i].value.substring(0, 2)) {
                    data.cell = i
                }
            }

            try {
                const response = await axios.post(api + "/" + shop + "/shifts/" + date + '/', {
                    role: data.role,
                    employee: data.user,
                    section: section,
                    cell: data.cell,
                    row: data.row,
                    time_from: data.time_from,
                    time_to: data.time_to,
                    description: data.description
                },
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                
                dispatch('fetchEmployees')
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        async editShift({ state, dispatch }, data) {
            let date = router.currentRoute.value.query.date
            let shop = router.currentRoute.value.query.code
            let section = null

            if(state.sections.length > 1) {
                section = state.sections.filter(x => x.name === state.selectedSection).map(x => { return x.id; }).toString()
            }

            if(!data.cell) {
                for(let i = 0; i < state.times.length; i++) {
                    if(data.time_from.substring(0, 2) === state.times[i].value.substring(0, 2)) {
                        data.cell = i
                    }
                }
            }

            if(!data.row) {
                for(let row in state.table) {
                    if(state.table[row].empty) {
                        data.row = Number(row.substring(4))
                    } else {
                        data.row = Number(Object.keys(state.table).length-1)
                    }
                }
            }

            console.log(data);

            try {
                const response = await axios.patch(api + "/" + shop + "/shifts/" + date + '/' + data.shift_id +'/', {
                    role: data.role,
                    employee: data.user,
                    section: section,
                    cell: data.cell,
                    row: data.row,
                    time_from: data.time_from,
                    time_to: data.time_to,
                    description: data.description
                },
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                
                dispatch('fetchEmployees')
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        async deleteShift({ dispatch }, id) {
            let date = router.currentRoute.value.query.date
            let shop = router.currentRoute.value.query.code

            try {
                const response = await axios.delete(api + "/" + shop + "/shifts/" + date + '/' + id + '/',
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                dispatch('fetchEmployees')
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        async shiftAutoFill({ dispatch, state }, data) {
            let date = router.currentRoute.value.query.date
            let shop = router.currentRoute.value.query.code

            state.loading_autofill = true

            try {
                const response = await axios.post("https://admin.tcode.online/api/projects/tubatay/" + shop + "/shifts-generate/" + date + '/', {
                    time_from: data.shift_start,
                    time_to: data.shift_end,
                    revenue: data.revenue,
                    average_check: data.average,
                    orders: data.traffic
                },
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                console.log(response.data);
                state.loading_autofill = false
                dispatch('dataGeneration')
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        getBindingShift({ commit }, shift_id) {
            let date = router.currentRoute.value.query.date
            let shop = router.currentRoute.value.query.code

            axios
            .get(api + "/"+ shop +"/shifts/" + date + '/' + shift_id,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setBindingShift', response.data)
            })
            .catch((error) => {
                console.log(error);
            })
        },

        getBindingEmployee({ commit }, employee_id) {
            let shop = router.currentRoute.value.query.code

            axios
            .get(api + "/"+ shop + "/shift-employees/" + employee_id,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setBindingEmployee', response.data)
            })
            .catch((error) => {
                console.log(error);
            })
        },

        // Фукнция которая запускает получение основных данных для страницы
        dataGeneration({ state, dispatch }) {
            if(state.sections.length === 0) {
                dispatch('fetchSections') // Получение секций (Прилавок/Кухня)
            }
            dispatch('fetchRoles') // Получение ролей сотрудников
            dispatch('fetchEmployees') // Получение списка сотрудников
        }
    }
}