import axios from 'axios'
import api from '@/api'
import router from '@/router';

export const ordersProductsModule = {
    namespaced: true,

    state:() => ({
        shops: null,
        categories: null,
        products: null,
        suppliers: null,
        cart: {
            items: [],
            suppliers: null,
            productCounts: 0,
            totalPrice: 0,
        },
        orderCreated: false,
        pages: null
    }),

    mutations: {
        setShops(state, data) {
            for(let i = 0; i < data.length; i++) {
                data[i].label = data[i].name
                data[i].value = data[i].id
            }

            state.shops = data
        },

        setCategories(state, data) {
            for(let i = 0; i < data.length; i++) {
                data[i].label = data[i].real_name
                data[i].value = data[i].id
                data[i].checked = true
            }

            state.categories = data
        },

        setProducts(state, data) {
            for(let i = 0; i < data.length; i++) {
                data[i].quantity = 0
            }
            console.log(data);
            state.products = data
        },

        setSuppliers(state, data) {
            for(let i = 0; i < data.length; i++) {
                data[i].label = data[i].name
                data[i].value = data[i].id
                data[i].checked = true
                data[i].quantity = 0
            }

            state.suppliers = data
        },

        setPages(state, data) {
            state.pages = data
        }
    },

    actions: {
        getShops({ commit }) {
            axios
            .get("https://admin.tcode.online/api/projects/tcode/shops/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setShops', response.data)
            })
            .catch((error) => {
                console.log(error);
            });
        },

        getCategories({ commit }) {
            axios
            .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/categories/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setCategories', response.data.results)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        getProducts({ state, rootState , commit }) {
            rootState.isLoading = true
            if(router.currentRoute.value.fullPath.split('?')[1]) {
                axios
                .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/products?" + router.currentRoute.value.fullPath.split('?')[1], {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    commit('setProducts', response.data.results)
                    commit('setPages', response.data.num_pages)

                    rootState.isLoading = false
                })
                .catch((error) => {
                    console.log(error.response);
                });
            } else {
                axios
                .get("https://admin.tcode.online/api/projects/tubatay-production/shops/production/products?ordering=name", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    commit('setProducts', response.data.results)
                    commit('setPages', response.data.num_pages)

                    rootState.isLoading = false
                })
                .catch((error) => {
                    console.log(error.response);
                });
            }
        },

        getSuppliers({ commit }) {
            axios
            .get("https://admin.tcode.online/api/projects/tubatay-production/suppliers/", {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                commit('setSuppliers', response.data)
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        inputProductCount({ state, dispatch }, data) {
            if(data.type === 'plus') {
                const product = state.products.find(product => product.id === data.id)

                if(!state.cart.suppliers || state.cart.suppliers.id === product.supplier.id) {
                    const elem = state.cart.items.find(item => item.id === product.id)

                    if(elem) {
                        elem.quantity += product.multiplicity
                        
                        localStorage.setItem('cart', JSON.stringify(state.cart.items))
                    } else {
                        product.quantity = product.multiplicity
                        state.cart.items.push(product)

                        const supplier = state.suppliers.find(item => item.id === product.supplier.id);
                        supplier.quantity++

                        if(!state.cart.supplier) {
                            state.cart.suppliers = supplier
                        }

                        localStorage.setItem('cart', JSON.stringify(state.cart.items))
                        localStorage.setItem('cart_suppliers', JSON.stringify(state.cart.suppliers))
                    }
                }
                
                dispatch('calculatePrice')
            } else if (data.type === 'minus') {
                for(let i = 0; i < state.cart.items.length; i++) {
                    if(state.cart.items[i].id === data.id && state.cart.items[i].quantity > 0) {
                        state.cart.items[i].quantity -= state.cart.items[i].multiplicity

                        if(state.cart.items[i].quantity < 1) {
                            if(state.cart.suppliers.id === state.cart.items[i].supplier.id) {
                                if(state.cart.suppliers.quantity > 1) {
                                    state.cart.suppliers.quantity --
                                } else {
                                    state.cart.suppliers.quantity = 0
                                    state.cart.suppliers = null
                                }
                            }
                            state.cart.items.splice(i, 1)
                        }

                        localStorage.setItem('cart', JSON.stringify(state.cart.items))
                        localStorage.setItem('cart_suppliers', JSON.stringify(state.cart.suppliers))
                    }
                }

                dispatch('calculatePrice')
            } else {
                for(let i = 0; i < state.cart.items.length; i++) {
                    if(state.cart.items[i].id === data.id) {
                        if(state.cart.suppliers.id === state.cart.items[i].supplier.id) {
                            if(state.cart.suppliers.quantity > 1) {
                                state.cart.suppliers.quantity --
                            } else {
                                state.cart.suppliers.quantity = 0
                                state.cart.suppliers = null
                            }
                        }

                        state.cart.items.splice(i, 1)
                    }

                    localStorage.setItem('cart', JSON.stringify(state.cart.items))
                    localStorage.setItem('cart_suppliers', JSON.stringify(state.cart.suppliers))
                }
                dispatch('calculatePrice')
            }
        },

        calculatePrice({ state }) {
            state.cart.totalPrice = 0
            state.cart.productCounts = 0

            for(let i = 0; i < state.cart.items.length; i++) {
                if(state.cart.items[i].quantity > 0) {
                    state.cart.totalPrice += state.cart.items[i].quantity * state.cart.items[i].price_float.toFixed(2)
                    state.cart.productCounts ++
                }
            }
        },
        sendOrder({ state, dispatch }, data) {
            if(typeof data.date !== 'string') {
                data.date = data.date.toLocaleDateString().split('.').reverse().join('-')
            }

            let order = {
                items: state.cart.items,
                delivery_to: data.shop,
                delivery_date: data.date,
                editable_order: data.editable
            }
            axios
            .post("https://admin.tcode.online/api/projects/tubatay-production/shops/production/personal/orders/", 
                order,
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    },
                    
                })
            .then((response) => {
                if(response.data.created) {
                    dispatch('orderClear')
                    dispatch('changeOrderStatus', true)
                }
            })
            .catch((error) => {
                console.log(error.response);
            });
        },

        fetchCart({ state, dispatch }) {
            const cart = JSON.parse(localStorage.getItem('cart')) ?? null
            const cart_suppliers = JSON.parse(localStorage.getItem('cart_suppliers')) ?? null
            
            if(cart) {
                state.cart.items = cart
            }

            if(cart_suppliers) {
                state.cart.suppliers = cart_suppliers
            }
            dispatch('calculatePrice')
        },  

        orderClear({ state }) {
            state.cart.productCounts = 0
            state.cart.totalPrice = 0
            state.cart.date = null
            state.cart.suppliers = null
            state.cart.items = []

            for(let i = 0; i < state.products.length; i++) {
                state.products[i].quantity = 0

                const elem = state.suppliers.find(item => item.id === state.products[i].supplier.id);
                elem.quantity = 0
            }

            localStorage.removeItem('cart')
            localStorage.removeItem('cart_suppliers')
        },

        changeOrderStatus({ state }, status) {
            state.orderCreated = status
        },

        async productEdit({ dispatch }, data) {
            try {
                const response = await axios.put("https://admin.tcode.online/api/projects/tubatay-production/shops/production/products/"+ data.id +"/",
                data.data,
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                dispatch('getProducts')
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        }
    }
}