import axios from 'axios'
import api from '@/api'

export const TimeManagementModule = {
    namespaced: true,

    state:() => ({
        isLoading: true,
        employees: null,
        employeeData: null
    }),

    mutations: {
        setEmployees(state, data) {
            state.employees = data
        },

        setEmployeeData(state, data) {
            state.employeeData = data
        }
    },

    actions: {
        getEmployees({ state, commit }, data) {
            state.isLoading = true

            axios
            .get(api + "/" + data + "/shift-employees/",
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                console.log(response.data);
                commit('setEmployees', response.data)
                state.isLoading = false
            })
            .catch((error) => {
                console.log(error);
            });
        },

        getEmployeeData({ state, commit }, data) {
            state.isLoading = true

            axios
            .get(api + "/" + data.shop + "/shift-employees/" + data.id,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                console.log(response.data);
                commit('setEmployeeData', response.data)
                state.isLoading = false
            })
            .catch((error) => {
                console.log(error);
            });
        },

        saveEmployeeChanges({ state, dispatch }, data) {
            for(let item in data.day) {
                if(data.day[item] === "") {
                    data.day[item] = null
                }
            }
            
            axios
            .patch(api + "/" + data.shop + "/shift-opportunities/" + data.day.id + '/', 
            data.day,
            {
                headers: {
                    Authorization: 'Token ' + localStorage.getItem('token')
                },
                
            })
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                alert(error)
            });
        }
    }
}