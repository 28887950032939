import axios from 'axios'
import api from '@/api'

export const gameModule = {
    namespaced: true,

    state:() => ({
        questions: null,
        currentQuestionID: null,
        currentQuestion: null,
    }),

    mutations: {
        setQuestions(state, data) {
            for(let i = 0; i < data.length; i++) {
                if(!data[i].is_completed || data[i].is_completed == false) {
                    if(!state.currentQuestionID) {
                        state.currentQuestionID = data[i].id
                    }
                }
            }
            state.questions = data
        },

        setCurrentQuestionID(state, data) {
            state.currentQuestionID = data
        },

        setCurrentQuestion(state, data) {
            state.currentQuestion = data
        }
    },

    actions: {
        async fetchQuestion({ commit }) {
            try {
                const response = await axios.get(api + "/game-products/", {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                const data = response.data
                console.log(response.data);
                commit('setQuestions', data)
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        async fetchCurrentQuestion({ commit }, id) {
            try {
                const response = await axios.get(api + '/game-products/' + id + '/', {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                console.log(response.data);
                commit('setCurrentQuestion', response.data)
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },

        async completeQuestion({ commit }, id) {
            try {
                const response = await axios.post(api + '/game-progress/', 
                {
                    game_product: id,
                    is_completed: true
                },
                {
                    headers: {
                        Authorization: 'Token ' + localStorage.getItem('token')
                    }
                })
                console.log(response.data);
                commit('setCurrentQuestion', response.data)
            } catch(err) { // Если есть ошибка
                alert(err)
            }
        },
    }
}